import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { environment } from 'src/assets/environments/environment';
import { auth } from '../../_Models/auth';
import { AccountService } from '../../_Services/_Shared/account.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AlertInfo } from '../../_Models/Portal/AlertInfo';
import { DailyCourse } from '../../_Models/Portal/DailyCourse';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GetAppLink } from 'src/app/_Models/Portal/getAppLink';
import { postDeptList } from 'src/app/_Models/AD/ADPUB/ADPUB0103/postDeptList';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    user!: auth;
    postList: postDeptList[] = [];
    alertInfo!: AlertInfo[];
    dailyCourse!: DailyCourse[];
    dailyCourseSlides: any = [[]];
    baseUrl = environment.apiUrl;
    ImgUrl!: SafeUrl;
    appLink!: GetAppLink;
    EncryptData = {
        DataKey: ''
    };

    constructor(public accountService: AccountService,
        private router: Router,
        private http: HttpClient,
        private domSanitizer: DomSanitizer,
        private systemService: SystemService,
        private translateService: TranslateService,
        private toastr: ToastrService,) {
        this.accountService.currentUser$.pipe(take(1)).subscribe(user => {
            if (user != null) {
                this.user = user;
                if (user.PIC !== null) {
                    this.ImgUrl = this.domSanitizer.bypassSecurityTrustUrl(user.PIC);
                }
                if(user.PIC=="data:image/jpeg;base64,")
                {
                     this.defaultImg("../../../../assets/Image/預設圖片.jpg").then((img)=>{
                        if (typeof(img) === 'string') {
                            user.PIC=img;
                         }
                        this.ImgUrl = this.domSanitizer.bypassSecurityTrustUrl(user.PIC);
                    });
                }
            }

        }, error => this.toastr.error(error.error));
    }


    ngOnInit(): void {
        this.translateService.use(this.systemService.currentLanguage)
        this.getAlertInfo();
        this.getDailyCourse();
        this.getAppLink();
        this.BulletinBoard();
    }

    defaultImg(imgurl:string){
        const test = new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest()
            xhr.open('get', imgurl, true)
            xhr.responseType = 'blob'
            xhr.onload =  function () {
              if (this.status == 200) { 
                let blob = this.response
                  let oFileReader =  new  FileReader()
                  oFileReader.onloadend =  function (e) {
                      var tar=e.target;
                      var base64: unknown;
                      if(tar != null)
                      {
                          base64  = tar.result
                      }
                      resolve(base64);
                  }
                  oFileReader.readAsDataURL(blob)
              }
            }
       xhr.send()
        });
       return test;
    }



    //通知訊息
    getAlertInfo() {
        this.http.get<any>(this.baseUrl + 'Portal/Get_AlertInfo').subscribe((response: any) => {
            if (response.Info == false) {
                //this.toastr.info(response.Message, "通知訊息");
                return;
            }
            this.alertInfo = response;
        });
    }

    //今日課程
    getDailyCourse() {
        this.http.get<DailyCourse>(this.baseUrl + "Portal/Get_DailyCourse").subscribe((response: any) => {
            if (response.Info == false) {
                //this.toastr.info(response.Message, "今日課程");
                return;
            }
            this.dailyCourse = response;
            this.dailyCourseSlides = this.chunk(this.dailyCourse, 2);
        });
    }

    //登出
    logout(): void {
        this.accountService.logout();
        this.router.navigateByUrl('/');
    }

    chunk(arr: any, chunkSize: any) {
        let R = [];
        if (arr) {
            for (let i = 0; i < arr.length; i += chunkSize) {
                R.push(arr.slice(i, i + chunkSize));
            }
        }
        return R;
    }


    openNewTab(url: string) {
        window.open(url, '_blank');
    }

    //應用程式
    getAppLink() {
        this.http.get<any>(this.baseUrl + "Portal/Get_AppLink").subscribe((response) => {
            if (response.Info == false) {
                //this.toastr.warning(response.Message);
                return;
            }
            this.appLink = response;
        });
    }

    //其他應用程式
    posturl(url: string) {
        this.http.get<any>(this.baseUrl + "Portal/Get_EncryptData").subscribe((response) => {
            this.EncryptData = response;
            window.open(url.replace('!EncryptData', this.EncryptData.DataKey), '_blank');
        })
    }

    //公佈欄
    BulletinBoard(): void {
        this.http.get<postDeptList[]>(this.baseUrl + "Portal/BulletinBoard").subscribe(response => {
            this.postList = response.slice(0, 5);
        });
    }

    //跳轉至學生或是教職員公佈欄
    GoToBulletinBoard() {

        var Type = JSON.parse(sessionStorage.getItem('auth') || '').Type;
        if (Type == "0"){
            this.router.navigate(['/STDW700_01']);
        }

        if (Type == "1"){
            this.router.navigate(['/ADPUB0103']);
        }

    }
}
