<div class="container">
    <div class="row">
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="Years">學年</span>
                <select class="form-select shadow-none" name="Years" [(ngModel)]="model.Years">
                    <option value="" selected>--全部--</option>
                    <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="Term">學期</span>
                <select class="form-select shadow-none" name="Term" [(ngModel)]="model.Term">
                    <option value="" disabled>--全部--</option>
                    <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text">學制</span>
                <select class="form-select shadow-none"
                    [(ngModel)]="model.EduNo">
                    <option value="" selected>--請選擇學制--</option>
                    <option *ngFor="let item of eduList" value="{{item.Edu_No}}">{{item.Edu_Name}}</option>
                </select>
            </div>
        </div>        
        <div class="col-md-3">
            <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="Approve">是否核准</span>
                <select class="form-select shadow-none" name="Approve" [(ngModel)]="model.Approve">
                    <option value="" disabled>--全部--</option>
                    <option value="Y">是</option>
                    <option value="N">否</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <button class="search-btn me-3 float-start" (click)="getStdKeepList()">查詢</button>
            <button class="other-Button-gray me-3 float-start" (click)="openModel(template,-1)">新增</button>
            <button class="other-Button-gray me-3 float-start" (click)="deleteData()">刪除</button>
        </div>
    </div>
    <hr>
    <div class="DataList">
        <table class="table table-hover table-striped text-center">
            <thead class="thead-dark-red">
                <tr>
                    <td class="W40">刪除</td>
                    <td class="W50"><p class="PM1">保留</p><p class="PM1">學年</p></td>
                    <td class="W50"><p class="PM1">保留</p><p class="PM1">學期</p></td>
                    <td class="W70">學號</td>
                    <td class="W70">姓名</td>
                    <td class="W50">狀態</td>
                    <td class="W100">班級</td>
                    <td class="W100">原因</td>
                    <td class="W100">保留日期</td>
                    <td class="W100">核准日期</td>
                    <td class="W40">核准</td>
                    <td class="W100">起迄年月</td>
                    <td class="W100">備註</td>
                    <td class="W50">名額內</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of stdKeepList; let i = index" class="pointer">
                    <td>
                        <input type="checkbox" (click)="setDataKey(item.DataKey)">
                    </td>
                    <td>{{item.Years}}</td>
                    <td>{{item.Term}}</td>
                    <td class="text-primary"><a (click)="openModel(template,i)">{{item.StdNo}}</a></td>
                    <td class="std">{{item.StdName}}</td>
                    <td>保留</td>
                    <td>{{item.ClassName}}</td>
                    <td>{{item.KeepName}}</td>
                    <td>{{item.KeepDate}}</td>
                    <td>{{item.ApproveDate}}</td>
                    <td>{{item.Approve}}</td>
                    <td>{{item.KeepDateStart}}~{{item.KeepDateEnd}}</td>
                    <td>{{item.Memo}}</td>
                    <td>{{item.quota}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">新增/修改保留入學資料</h4>
        <button type="button" class="btn-close btn-close-white pull-right shadow-none" aria-label="Close"
            (click)="modalRef.hide()">

        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="newYears">學年</span>
                    <select class="form-select shadow-none" name="newYears" *ngIf="newStdKeepData.DataKey==''" [(ngModel)]="newStdKeepData.Years">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                    <input type="text" class="form-control shadow-none" disabled *ngIf="newStdKeepData.DataKey!=''" [(ngModel)]="newStdKeepData.Years">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="newTerm">學期</span>
                    <select class="form-select shadow-none" name="newTerm" *ngIf="newStdKeepData.DataKey==''" [(ngModel)]="newStdKeepData.Term">
                        <option value="" disabled>--全部--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                    <input type="text" class="form-control shadow-none" disabled *ngIf="newStdKeepData.DataKey!=''" [(ngModel)]="newStdKeepData.Term">                    
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newYears">學號</span>
                        <input type="text" class="form-control shadow-none" *ngIf="newStdKeepData.DataKey==''" required [(ngModel)]="newStdKeepData.StdNo">
                        <input type="text" class="form-control shadow-none" disabled *ngIf="newStdKeepData.DataKey!=''" [(ngModel)]="newStdKeepData.StdNo">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newTerm">原因</span>
                    <select class="form-select shadow-none" name="newTerm" [(ngModel)]="newStdKeepData.KeepNo">
                        <option value="" disabled>--請選擇--</option>
                        <option *ngFor="let item of keepReasonList" value="{{item.ItemValue}}">{{item.ItemName}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="newYears">應重入學年</span>
                    <select class="form-select shadow-none" name="newYears" [(ngModel)]="newStdKeepData.EndYear">
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>              
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newYears">保留日期</span>
                    <input type="date" class="form-control shadow-none" required [(ngModel)]="newStdKeepData.KeepDate">
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newApprove">保留起始年月</span>
                    <input type="text" class="form-control shadow-none" required [(ngModel)]="newStdKeepData.KeepDateStart">
                </div>
            </div> 
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newApprove">保留迄止年月</span>
                    <input type="text" class="form-control shadow-none" required [(ngModel)]="newStdKeepData.KeepDateEnd">
                </div>
            </div>          
        </div>        
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="newYears">申請日期</span>
                    <input type="text" class="form-control shadow-none" [disabled]="true" [(ngModel)]="newStdKeepData.ApplyDate">
                </div>
            </div>            
            <div class="col-md-2">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newApprove">是否核准</span>
                    <select class="form-select shadow-none" name="newApprove" [(ngModel)]="newStdKeepData.Approve">
                        <option value="Y">是</option>
                        <option value="N">否</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newTerm">核准日期</span>
                    <input type="date" class="form-control shadow-none"
                        [(ngModel)]="newStdKeepData.ApproveDate">
                </div>
            </div>            
            <div class="col-md-4">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="newApprove">文號</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="newStdKeepData.KeepMoeChar">
                    <span class="input-group-text" id="newApprove">字號</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="newStdKeepData.KeepMoe">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newApprove">名額內</span>
                    <select class="form-select shadow-none" name="newApprove" [(ngModel)]="newStdKeepData.quota">
                        <option value="" disabled>--全部--</option>
                        <option value="Y">是</option>
                        <option value="N">否</option>
                    </select>
                </div>
            </div>            
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                        <span class="input-group-text" id="newApprove">保留延長</span>
                    <select class="form-select shadow-none" name="newApprove" [(ngModel)]="newStdKeepData.Delay">
                        <option value="" disabled>--全部--</option>
                        <option value="Y">是</option>
                        <option value="N">否</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="newYears">重入學年</span>
                    <select class="form-select shadow-none" name="newYears" [(ngModel)]="newStdKeepData.RecoverYear">
                        <option value="" selected>--全部--</option>
                        <option *ngFor="let item of Years" [value]="item">{{item}}學年</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group flex-nowrap mb-3">
                    <span class="input-group-text" id="newTerm">重入學期</span>
                    <select class="form-select shadow-none" name="newTerm" [(ngModel)]="newStdKeepData.RecoverTerm">
                        <option value="" disabled>--全部--</option>
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>                  
                </div>
            </div>            
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="">備註</label>
                <textarea type="text" class="form-control shadow-none" [(ngModel)]="newStdKeepData.Memo"
                    appTextareaAutoresize></textarea>
            </div>
        </div>
        <div class="mt-3">
            <button class="add-btn mx-auto" *ngIf="newStdKeepData.DataKey==''" (click)="newData()">新增</button>
            <button class="add-btn mx-auto" *ngIf="newStdKeepData.DataKey!=''" (click)="newData()">儲存</button>
        </div>
    </div>
</ng-template>